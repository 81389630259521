<style scoped>
	.dialog_page_footer{text-align: right;}
	.community_box{max-height: 12rem;overflow-y: auto;}
</style>

<template>
	<div class="dialog_page">
		<div class="dialog_page_header"></div>
		<div class="dialog_page_body">
			<el-form label-width="60px">
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="姓名">
							<el-input v-model="formData.name"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="电话">
							<el-input v-model="formData.phone"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="职责">
							<el-input v-model="formData.duty"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="状态">
							<el-switch v-model="formData.status" active-text="开启" inactive-text="禁用" active-value="1" inactive-value="99"></el-switch>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="权限">
					<div class="community_box">
						<el-checkbox-group v-model="formData.auth_item_ids">
							<el-checkbox v-for="item in communityList" :label="item.auth_item_id" name="type">
								{{item.name}}
							</el-checkbox>
						</el-checkbox-group>
					</div>
				</el-form-item>
			</el-form>
		</div>
		<div class="dialog_page_footer">
			<el-button @click="clickCancel">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="api_saveCommit">立即保存</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props: ['pageParams'],
		data() {
			return {
				btnSaveLoading: false,
				communityList: [], //小区列表
				roleList: [], //角色列表
				formData: {
					name: '',
					phone: '',
					duty: '',
					auth_codes: []
				}
			}
		},
		created: function() {
			_this = this;
			_this.api_getCommiunityList();
		},
		mounted: function() {

		},
		methods: {
			// _ ** 点击取消
			clickCancel() {
				_this.$emit('childrenEvent', {
					type: 'close'
				});
			},
			// _ ** 获取列表
			api_getCommiunityList() {
				_this._getApi('/wy/staffAuth/getStaffAuthList', {}).then((res) => {
					if (res.code == 1) {
						_this.communityList = res.data;
						var newData = JSON.parse(JSON.stringify(_this.pageParams));
						var has_auth_codes = newData.auth_item_ids;
						has_auth_codes = has_auth_codes.split(',');
						has_auth_codes = has_auth_codes.map(Number)
						newData.auth_item_ids = has_auth_codes;
						newData.status = newData.status+'';
						_this.formData = newData;
					} else {
						return;
					}
				}).catch((err) => {});
			},
			// _ ** 获取角色列表
			api_getRoleList() {
				_this._getApi('/wy/staffAuth/getStaffAuthList', {}).then((res) => {
					if (res.code == 1) {
						_this.roleList = res.data.list;
					} else {

					}
				}).catch((err) => {})
			},

			// _ ** 添加保存
			api_saveCommit() {
				_this.btnSaveLoading = true;
				var params = _this.formData;
				params.residence_id = _this.pageParams.id;
				_this._postApi('/wy/staff/update', params).then((res) => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$emit('childrenEvent', {
							type: 'success'
						});
					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {

				})
			}
		}
	}
</script>
